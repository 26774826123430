<template>
  <div id="LocaleChanger">
    <button
      type="button"
      v-for="(title, lang) in locales"
      :key="lang"
      @click="$root.$i18n.locale = lang"
    >
      {{ title }}
    </button>
  </div>
</template>

<script>
const localeTitles = {
  en: "English",
  tr: "Türkçe",
};

export default {
  computed: {
    locales() {
      const list = {};
      this.$root &&
        this.$root.$i18n &&
        Object.keys(this.$root.$i18n.messages).map((lang) => {
          list[lang] = localeTitles[lang] || lang;
        });

      return list;
    },
  },
};
</script>

<style>
#LocaleChanger {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 0.5rem;
  margin-top: 2rem;
}
#LocaleChanger > button {
  background: transparent;
  padding: 0.5rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  color: rgba(0, 0, 0, 0.5);
  font-size: 90%;
  outline: none;
}
</style>
