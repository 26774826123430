import axios from "axios";

const base = window.location.href.indexOf("192.168.1.234") >= 0 ? "http://192.168.1.234:8001" : "";

const getErrorCode = (error) => {
    if (error && error.response && error.response.status) return "error_" + error.response.status;
    else if (error && error.code) return "error_" + error.code;
    else if (error && error.message && typeof error.message === "string") return error.message.toString().replace(/\s/g, "");
    else return error;
};

const getUrlVars = () => {
    var vars = {}, hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars[hash[0]] = hash[1];
    }
    return vars;
};

export default {
    ifttt: {
        auth(userid, password) {
            const query = getUrlVars();
            const data = Object.assign({ userid, password }, query);
            return new Promise((resolve, reject) => {
                axios({
                    method: "POST",
                    url: `${base}/ifttt/auth`,
                    data
                })
                    .then((result) => {
                        if (result && result.data && result.data.redirect_uri) window.location.href = decodeURIComponent(result.data.redirect_uri);
                        else resolve(result.data);
                    })
                    .catch((error) => {
                        reject(getErrorCode(error));
                    });

            });
        }
    },

    google: {
        auth(userid, password) {
            const query = getUrlVars();
            const data = Object.assign({ userid, password }, query);
            return new Promise((resolve, reject) => {
                axios({
                    method: "POST",
                    url: `${base}/google/actions/auth`,
                    data
                })
                    .then((result) => {
                        if (result && result.data && result.data.redirect_uri) window.location.href = decodeURIComponent(result.data.redirect_uri);
                        else resolve(result.data);
                    })
                    .catch((error) => {
                        reject(getErrorCode(error));
                    });

            });
        }
    },

    a6: {
        auth(userid, password) {
            return new Promise((resolve, reject) => {
                axios({
                    method: "POST",
                    url: `${base}/a6/auth`,
                    data: { userid, password }
                })
                    .then((res) => resolve(res.data.data))
                    .catch(reject);
            });
        }
    }
};