<template>
  <div id="ErrorMessage">
    <div v-if="error">
      <i class="fas fa-fw fa-exclamation-circle"></i>
      <div v-if="typeof error === 'string'">{{ error }}</div>
      <div v-else>
        <div>{{ error.title }}</div>
        <div v-if="error.description">{{ error.description }}</div>
        <div v-for="(description, $index) in error.descriptions" :key="$index">
          &mdash; {{ description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: [Object, String],
      required: false,
      default: null,
    },
  },
};
</script>

<style>
#ErrorMessage {
  display: flex;
  flex-direction: column;
}

#ErrorMessage > div {
  display: flex;
  flex-direction: row;
  background-color: rgba(200, 0, 0, 0.2);
  padding: 10px;
  border-radius: 8px;
  border: 1px solid rgba(200, 0, 0, 0.3);
  color: #900;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
  animation: tada 1s;
}
#ErrorMessage > div > i {
  font-size: 200%;
  align-self: center;
  padding: 0 15px 0 5px;
  color: #a00;
}
#ErrorMessage > div > div > div:first-of-type {
  font-weight: bold;
  font-size: 90%;
}
#ErrorMessage > div > div > div:not(:first-of-type) {
  font-size: 70%;
}
#ErrorMessage > div > div > div {
  margin-bottom: 5px;
}

#x .error {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #aa000011;
  border-bottom: 2px solid #a00;
  background-color: #fee;
  color: #a00;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.4);
  animation: tada 1s;
  border-radius: 5px;
}
#x .error-wrapper {
  position: relative;
  height: 64px;
  max-height: 65px;
}
</style>