export default {
    messages: {
        en: {
            abstract: "{service} needs the following permissions!",
            permission: {
                scenes: {
                    title: "Scenes",
                    description: "It can monitor and run scenes."
                },
                devices: {
                    title: "Devices",
                    description: "It can monitor existing devices, access them and change their status."
                }
            }
        },
        tr: {
            abstract: "{service} aşağıdaki yetkilere ihtiyaç duyuyor!",
            permission: {
                scenes: {
                    title: "Senaryolar",
                    description: "Mevcut senaryolarınızı listeleyebilir ve çalıştırabilir."
                },
                devices: {
                    title: "Cihazlar",
                    description: "Mevcut cihazlarınızı izleyebilir, erişebilir ve durumlarını değiştirebilir."
                }
            }
        }
    }
};