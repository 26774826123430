<template>
  <div class="title-wrapper">
    <img :src="$props.src" alt="IFTTT" />
    <img
      src="@/assets/service_link.svg"
      class="link"
      :class="{ working: working }"
    />
    <img src="@/assets/service_aypro.svg" alt="AYPRO Smart Life" />
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    working: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style>
.title-wrapper {
  max-height: 9rem;
  display: flex;
  flex-direction: row;
  justify-content: center;

  padding-bottom: 25px;
  margin-bottom: 25px;
}
.title-wrapper img:not(.link) {
  position: relative;
  width: 5rem;
  height: 5rem;
  border: 0.5rem solid rgba(0, 0, 0, 0);
  border-radius: 5rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}
.title-wrapper img:not(.link):not(:first-of-type) {
  margin-left: -0.5rem;
}

.title-wrapper img.link {
  position: relative;
  border-radius: 2rem;
  z-index: 9;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
}

.title-wrapper img.link:not(.working) {
  width: 2rem;
  height: 2rem;
  animation: shakeX;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  margin-left: -0.5rem;
  border: 1px solid #fff;
}

.title-wrapper img.link.working {
  width: 2.1rem;
  height: 2.1rem;
  animation: spin 0.7s;
  animation-iteration-count: infinite;
  margin-left: -0.6rem;
  border: 3px solid #fff;
}

.title-wrapper img {
  align-self: center;
}

@keyframes signal {
  0%,
  100% {
    border-color: rgba(0, 0, 0, 0);
  }
  50% {
    border-color: rgba(0, 0, 0, 0.1);
  }
}
</style>