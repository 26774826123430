<template>
  <div id="AccessList">
    <p>{{ $t("abstract", { service }) }}</p>
    <div class="item" v-for="(perm, $key) in accessList" :key="$key">
      <div>{{ perm.title }}</div>
      <small>{{ perm.description }}</small>
    </div>
  </div>
</template>

<script>
import i18n from "@/locales/components/AccessList";

export default {
  i18n,
  props: {
    permissions: {
      type: Array,
      required: true,
    },
    serviceName: {
      type: String,
      required: true,
    },
  },
  computed: {
    service() {
      return this.$props.serviceName;
    },
    accessList() {
      const list = [];
      this.$props.permissions.map((p) => {
        list.push(this.$t(`permission.${p}`, { service: this.$props.service }));
      });
      return list;
    },
  },
};
</script>
