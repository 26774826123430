<template>
  <div id="LoginView">
    <form @submit="callSubmit" @reset="callReset">
      <!-- User ID -->
      <div class="input-field">
        <label for="LoginViewUserName">{{ $t("labels.username") }}</label>
        <input
          :type="$props.usernameFieldType"
          :readonly="isBusy"
          v-model="authData.username"
          id="LoginViewUserName"
          name="username"
          autocomplete="username"
          aria-autocomplete="username"
          aria-required="true"
          required
        />
      </div>

      <!-- Password -->
      <div class="input-field">
        <label for="LoginViewPassword">{{ $t("labels.password") }}</label>
        <input
          v-model="authData.password"
          type="password"
          id="LoginViewPassword"
          name="password"
          autocomplete="current-password"
          aria-autocomplete="current-password"
          aria-required="true"
          :readonly="isBusy"
          required
        />
      </div>

      <!-- Actions -->
      <div class="actions">
        <!-- <div class="spacer"></div> -->
        <button type="reset" v-if="false">{{ $t("buttons.cancel") }}</button>
        <button type="submit" :disabled="isBusy" :class="{ loading: isBusy }">
          {{ $t("buttons.submit") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import i18n from "@/locales/components/LoginView";
export default {
  i18n,
  data() {
    return {
      authData: {
        username: "",
        password: "",
      },
    };
  },
  props: {
    usernameFieldType: {
      type: String,
      required: false,
      default: "text",
    },
    isBusy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    callSubmit($event) {
      $event && $event.preventDefault && $event.preventDefault();
      const me = this;
      me.$emit("submit", me.authData);
    },
    callReset() {
      this.$emit("reset");
    },
  },
};
</script>

<style>
#LoginView form input {
  background: #fff;
  padding: 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}
#LoginView form label {
  margin-top: 5px;
  margin-bottom: 3px;
}
#LoginView form button {
  padding: 10px 15px;
  background: #efefef;
  margin: 5px 0;
  text-transform: uppercase;
}

#LoginView form button[type="submit"] {
  background-color: #032539;
  color: #fbf3f2;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5);
}

#LoginView form input[type],
#LoginView form button {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
  flex: 1;
}

#LoginView form .input-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5em;
}
#LoginView form .actions {
  display: flex;
}
#LoginView .spacer {
  flex: 1;
}
#LoginView form .actions > button:not(:first-of-type) {
  margin-left: 5px;
}

#LoginView form [disabled] {
  opacity: 0.8;
}
</style>