export default {
    messages: {
        en: {
            labels: {
                username: "User ID",
                password: "Password"
            },
            buttons: {
                submit: "Allow",
                cancel: "Decline"
            },
        },
        tr: {
            labels: {
                username: "Kullanıcı Kimliği",
                password: "Şifre"
            },
            buttons: {
                submit: "İzin ver",
                cancel: "İptal"
            },
        }
    }
};